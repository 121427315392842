import { clamp } from 'lodash'
import BaseController from './base_controller'

export default class FetchAutocompleteResultsController extends BaseController {
  downKey = 'ArrowDown'
  upKey = 'ArrowUp'
  navigationKeys = [this.downKey, this.upKey]

  static outlets = ['fetch-autocomplete']

  static targets = ['result']

  static classes = ['selected']

  connect () {
    super.connect()

    // Index of the selected option in the results list.
    this.index = -1
  }

  selectResult (event) {
    this.fetchAutocompleteController().selectResult(event)
  }

  selectResultFromEnter (event) {
    event.preventDefault()
    this.clickSelectedResult()

    const blur = new Event('blur')
    event.target.dispatchEvent(blur)
  }

  clickSelectedResult () {
    const el = this.resultTargets[this.index]
    if (el) {
      const mousedown = new Event('click')
      el.dispatchEvent(mousedown)
    }
  }

  // Method to navigate through our list results using
  // the arrow keys and enter key.
  // @param event [Event] the event we want to check
  navigateResults (event) {
    event.preventDefault()
    const prevIndex = this.index
    let newIndex = -1

    if (event.type === 'mouseover') {
      newIndex = this.resultTargets.indexOf(event.target)
    } else if (event.key === this.upKey) {
      newIndex = this.index - 1
    } else if (event.key === this.downKey) {
      newIndex = this.index + 1
    } else {
      return
    }

    // If no valid index, take no action.
    if (newIndex === -1) {
      return
    }

    // Remove the old selected state.
    if (this.resultTargets[prevIndex]) {
      this.resultTargets[prevIndex].classList.remove(this.selectedClass)
    }

    // Select the new result.
    this.index = clamp(newIndex, -1, this.resultTargets.length - 1)
    if (this.index >= 0) {
      this.resultTargets[this.index].focus()
      this.resultTargets[this.index].scrollIntoViewIfNeeded()
      this.resultTargets[this.index].classList.add(this.selectedClass)
    }
  }

  fetchAutocompleteController () {
    return this.fetchAutocompleteOutlets[0] // Assume a single autocomplete
  }
}
